<template>
  <section>
    <b-row class="match-height">

      <b-col cols="12">
        <!--  -->
        <form-wizard
          ref="formWizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          finish-button-text="Ver meu plano"
          next-button-text="Próximo"
          class="steps-transparent"
          @on-complete="formSubmitted"
        >
          <!-- hire package -->
          <tab-content
            title="Sacola"
            icon="feather icon-shopping-bag"
          >
            <b-row class="mb-1 match-height">
              <!-- coluna de ranges -->
              <b-col
                cols="4"
              >
                <b-card class="box-prices">
                  <b-row>
                    <b-col
                      cols="12"
                      class="d-flex align-items-center flex-column"
                    >
                      <h5>
                        <strong>Preço por lead*</strong>
                      </h5>
                    </b-col>
                  </b-row>

                  <template v-for="range in ranges">
                    <b-row
                      :key="range.title"
                      class="mt-1"
                    >
                      <b-col cols="6">
                        <b-card-text class="d-flex align-items-center flex-column">
                          {{ range.title }}
                        </b-card-text>
                      </b-col>
                      <b-col cols="6">
                        <b-card-text class="d-flex align-items-center flex-column">
                          <template v-if="range.price">
                            {{ new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(range.price) }}
                          </template>

                          <a
                            v-else
                            href="https://api.whatsapp.com/send?phone=5521977253975&text=Quero%20mudar%20meu%20plano%20indiquei%20para%20enterprise%20poderia%20me%20ajudar?%20"
                            target="_blank"
                          >
                            <b-button
                              size="sm"
                              variant="success"
                              class="d-flex justify-content-center"
                              :disabled="$can('edit', 'settingFinancial') === false"
                            >
                              Solicitar
                            </b-button>
                          </a>
                        </b-card-text>
                      </b-col>
                    </b-row>
                  </template>

                  <b-row>
                    <b-col
                      cols="12"
                      class="d-flex align-items-center flex-column mt-2"
                    >
                      <b-card-text>
                        *Leads válidos por 30 dias
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <!-- final coluna de ranges -->
              <b-col cols="8">
                <b-card>
                  <!--  -->
                  <b-row
                    v-if="!activeAutoRenew"
                    class="mb-2"
                  >
                    <b-col
                      cols="12"
                      class="mb-1"
                    >
                      <h5>
                        <strong>Escolha o que desejar fazer</strong>
                      </h5>
                    </b-col>
                    <b-col
                      cols="12"
                      class="d-flex"
                    >
                      <b-form-radio
                        v-model="renew"
                        name="creditos-avulso"
                        :value="false"
                      >
                        Comprar créditos avulso
                      </b-form-radio>
                      <b-form-radio
                        v-model="renew"
                        class="ml-2"
                        name="recarga-automatica"
                        :value="true"
                      >
                        Comprar créditos com renovação automática
                      </b-form-radio>
                    </b-col>
                  </b-row>
                  <b-row
                    class="pt-1"
                  >
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h5>
                        <strong>
                          {{ renew ? 'Defina o plano ideal para o seu negócio:' : 'Quantos créditos deseja comprar?' }}
                        </strong>
                      </h5>
                    </b-col>
                  </b-row>
                  <b-row
                    class="m-1"
                    style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);"
                  >
                    <b-col
                      cols="4"
                      class="d-flex align-items-center flex-column px-3 py-1"
                    >
                      <h5>Leads de indicação</h5>
                      <b-form-spinbutton
                        v-model="indications"
                        variant="primary"
                        class="mt-2 spinbutton-primary"
                        min="20"
                        max="999"
                      />
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex align-items-center flex-column py-1"
                    >
                      <h5>Valor por indicação</h5>
                      <h4
                        class="mt-2"
                      >{{ new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(getActualPricePerIndication.price) }}</h4>
                    </b-col>
                    <b-col
                      cols="4"
                      class="box-total py-1"
                    >
                      <h5>Total da compra</h5>
                      <template v-if="getActualPricePerIndication.actualRange > 0">
                        <small>de <strike>
                          {{ new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(getTotalPrice.oldPrice) }}
                        </strike> por</small>

                      </template>

                      <strong>
                        {{ new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(getTotalPrice.newPrice) }}
                      </strong>
                    </b-col>
                  </b-row>
                  <!--  -->
                  <b-row class="py-1">
                    <b-col cols="12">
                      <vue-slider
                        v-model="indications"
                        :min="20"
                        :max="999"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="py-1 mt-2">
                    <b-col cols="12">
                      <h4><strong>
                        Regulamento
                      </strong></h4>
                      <p>O plano contratado possui o prazo de validade de 30 dias, ao final da vigência seu plano renovará automaticamente, se os leads acabarem antes seu plano ficara indisponível até a data da renovação.</p>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </tab-content>
          <!-- cartão de crédito -->
          <tab-content
            title="Pagamento"
            icon="feather icon-credit-card"
            :before-change="validateStepN"
          >
            <b-row class="match-height">
              <b-col cols="7">
                <b-card>
                  <validation-observer ref="step-1">
                    <!-- LISTA DE CARTÕES -->
                    <b-row
                      v-if="useCreditCardsSavedList"
                      class="cardsList mb-2"
                    >
                      <b-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="cartão de crédito"
                          rules="required"
                        >
                          <div class="mb-2">
                            <h5>
                              <strong>Escolha o cartão</strong>
                            </h5>

                            <b-overlay
                              :show="isAPICallInProgress"
                              rounded="sm"
                            >
                              <b-card
                                v-for="card in listSavedCreditCards"
                                :key="card.id"
                                class="box-credit-card d-flex flex-column justify-content-center"
                                :class="{'checked' : selectedCardId === card.id}"
                              >
                                <b-form-radio
                                  v-model="selectedCardId"
                                  :name="card.id"
                                  :value="card.id"
                                >
                                  <b-row>
                                    <b-col cols="2">
                                      <b-img
                                        v-if="card.flag"
                                        :src="require(`@/assets/images/svg/${card.flag}.svg`)"
                                        :alt="card.flag"
                                      />
                                      <b-img
                                        v-else
                                        :src="require('@/assets/images/svg/generic.svg')"
                                        :alt="card.flag"
                                      />
                                    </b-col>

                                    <b-col
                                      cols="8"
                                      class="pl-3 d-flex flex-column justify-content-center"
                                    >
                                      <p class="m-0">
                                        {{ card.name }}
                                      </p>
                                      <p class="m-0">
                                        <strong>
                                          {{ card.number }}
                                        </strong>
                                      </p>
                                    </b-col>
                                    <b-col
                                      v-if="defaultPaymentMethodId === card.id"
                                      cols="2"
                                      class="d-flex align-items-center justify-content-center"
                                    >
                                      <b-img
                                        src="@/assets/images/raty/star-on-2.png"
                                        alt="svg img"
                                      />
                                    </b-col>
                                  </b-row>
                                </b-form-radio>
                              </b-card>
                            </b-overlay>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!-- FIM DA LISTA DE CARTÕES -->

                    <b-row v-else>
                      <b-overlay
                        :show="isAPICallInProgress"
                        rounded="sm"
                      >
                        <b-row class="px-2">
                          <b-col
                            cols="12"
                            class="mb-2"
                          >
                            <h5>
                              <strong>Cartão de crédito</strong>
                            </h5>
                            <small class="text-muted">
                              Insira o cartão
                            </small>
                          </b-col>
                          <!--  -->
                          <b-col md="12">
                            <b-form-group
                              label="Número do cartão"
                              label-for="creditCard"
                            >

                              <validation-provider
                                #default="{ errors }"
                                name="número do cartão"
                                rules="required"
                              >
                                <b-input-group>
                                  <cleave
                                    id="creditCard"
                                    v-model="creditCardNumber"
                                    class="form-control"
                                    :raw="false"
                                    :options="creditCardMask"
                                    placeholder="1234 5678 1234 5678"
                                  />
                                  <b-input-group-append is-text>
                                    <div class="box-flag d-flex">
                                      <b-img
                                        :src="require(`@/assets/images/svg/${flag}.svg`)"
                                        :alt="flag"
                                      />
                                      <feather-icon
                                        icon="ActivityIcon"
                                        class="cursor-pointer"
                                        size="1"
                                      />
                                    </div>
                                  </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!--  -->
                          <b-col md="6">
                            <b-form-group
                              label="Vencimento"
                              label-for="validate"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="vencimento"
                                rules="required"
                              >
                                <cleave
                                  id="validate"
                                  v-model="validate"
                                  class="form-control"
                                  :raw="false"
                                  :options="validateMask"
                                  placeholder="MM/AA"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Código CVV"
                              label-for="cvcCode"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="código cvv"
                                rules="required|integer|max:3|min:3"
                              >
                                <cleave
                                  id="cvcCode"
                                  v-model="cvcCode"
                                  class="form-control"
                                  :raw="false"
                                  :options="cvvMask"
                                  placeholder="123"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!--  -->
                          <b-col md="12">
                            <b-form-group
                              label="Nome do titular do cartão"
                              label-for="managerName"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="nome do titular"
                                rules="required"
                              >
                                <b-form-input
                                  id="managerName"
                                  v-model="managerName"
                                  placeholder="Nome como está impresso no cartão"
                                  :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <!-- <b-col
                            cols="12"
                            class="d-flex mt-1"
                          >
                            <b-form-checkbox
                              v-model="saveCard"
                              checked="true"
                              class="custom-control-success"
                              name="check-renovation"
                              switch
                            >
                              <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                              </span>
                              <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                              </span>
                            </b-form-checkbox>

                            <div class="px-1">
                              <h6>Salvar cartão de crédito para futuras compras</h6>
                            </div>
                          </b-col> -->
                        </b-row>
                      <!--  -->

                      </b-overlay>
                    </b-row>

                    <b-row
                      v-if="listSavedCreditCards"
                      class="mt-2 mb-2"
                    >
                      <b-col
                        cols="12"
                        class="d-flex align-items-center justify-content-center"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="() => useCreditCardsSavedList = !useCreditCardsSavedList"
                        >
                          {{ useCreditCardsSavedList ? 'Comprar com outro cartão' : 'Utilizar um cartão salvo' }}
                        </b-button>
                      </b-col>
                    </b-row>

                  </validation-observer>
                </b-card>
              </b-col>

              <b-col cols="5">
                <b-card>
                  <b-row class="d-flex align-items-center justify-content-center">
                    <b-col
                      cols="12"
                    >
                      <p style="color: #B9B9C3">
                        Créditos para indicação
                      </p>
                    </b-col>
                    <b-col
                      cols="6"
                    >
                      <h5><strong>Resumo da compra</strong></h5>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right"
                    >
                      <h5 style="color: #7367F0">
                        <strong>Plano</strong>
                      </h5>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col cols="12">
                      <h5><strong>Detalhes do plano</strong></h5>
                    </b-col>
                    <b-col
                      cols="6"
                      class="mt-2"
                    >
                      <p>Campanhas</p>
                    </b-col>
                    <b-col
                      cols="6"
                      class="mt-2 text-right"
                    >
                      <p><strong>Ilimitadas</strong></p>
                    </b-col>
                    <b-col cols="6">
                      <p>Indicações</p>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right"
                    >
                      <p><strong>{{ indications }}</strong></p>
                    </b-col>
                    <b-col cols="6">
                      <p>Preço unitário do lead</p>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right"
                    >
                      <p class="success-text">
                        <strong>
                          {{ new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(getActualPricePerIndication.price) }}
                        </strong>
                      </p>
                    </b-col>
                    <b-col cols="12">
                      <p>Prazo de validade 30 dias</p>
                    </b-col>
                    <b-col
                      v-if="renew"
                      cols="12"
                    >
                      <p><u>Renovação automática (mensal)</u></p>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col cols="6">
                      <h5>Total</h5>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right"
                    >
                      <h5><strong>
                        {{ new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(getTotalPrice.newPrice) }}
                      </strong></h5>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>

          </tab-content>

          <!-- tela de sucesso -->
          <tab-content
            title="Concluído"
            icon="feather icon-check"
          >
            <b-row>
              <b-col
                cols="12"
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <h4>Compra efetuada com sucesso!</h4>
                <p>Agora você pode criar campanhas e receber indicações!</p>

                <b-img
                  src="@/assets/images/illustration/success-checkout.svg"
                  alt="svg img"
                  class="mb-2"
                />
              </b-col>
            </b-row>
          </tab-content>

        </form-wizard>
        <!--  -->
      </b-col>

    </b-row>

  </section>

</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, integer } from '@validations'

import Ripple from 'vue-ripple-directive'
import VueSlider from 'vue-slider-component'

import { FormWizard, TabContent } from 'vue-form-wizard'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import changePositionItemInArray from '@/utils/changePositionItemInArray'

import {
  BRow, BCol, BFormGroup, BFormInput, BCard, BImg, BFormRadio, BButton, BInputGroup, BInputGroupAppend, BOverlay, BFormSpinbutton, BCardText,
} from 'bootstrap-vue'

export default {
  name: 'CheckoutCreditCard',
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    // BFormCheckbox,
    Cleave,
    BCard,
    BImg,
    BFormRadio,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BFormSpinbutton,
    VueSlider,
    BCardText,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      indications: 20,
      renew: false,
      activeAutoRenew: null,
      selectedCardId: '',
      creditCardNumber: '',
      cvcCode: '',
      validate: '',
      managerName: '',
      flag: 'generic',
      saveCard: true,
      defaultPaymentMethodId: null,
      creditCardMask: {
        delimiters: [' '],
        blocks: [4, 4, 4, 4],
      },
      useCreditCardsSavedList: null,
      listSavedCreditCards: null,
      ranges: null,
      validateMask: {
        delimiters: ['/'],
        blocks: [2, 2],
        uppercase: true,
      },
      cvvMask: {
        blocks: [3],
      },
      required,
      integer,
    }
  },
  computed: {
    getActualPricePerIndication() {
      let actualRange = 0
      let price = 0

      if (this.ranges) {
        if (this.indications <= this.ranges[0].toEnd[1]) actualRange = 0
        if (this.indications >= this.ranges[1].toEnd[0] && this.indications <= this.ranges[1].toEnd[1]) actualRange = 1
        if (this.indications >= this.ranges[2].toEnd[0] && this.indications <= this.ranges[2].toEnd[1]) actualRange = 2
        if (this.indications >= this.ranges[3].toEnd[0] && this.indications <= this.ranges[3].toEnd[1]) actualRange = 3
        if (this.indications >= this.ranges[4].toEnd[0] && this.indications <= this.ranges[4].toEnd[1]) actualRange = 4

        price = this.ranges[actualRange].price
      }

      return { actualRange, price }
    },
    getTotalPrice() {
      const oldPrice = this.ranges ? this.indications * this.ranges[0].price : 0
      const newPrice = this.indications * this.getActualPricePerIndication.price

      return { oldPrice, newPrice }
    },
    getIdSelectPlan() {
      return this.ranges.filter(plan => plan.price === this.getActualPricePerIndication.price)[0].id
    },
    getFormattedInfoCreditCard() {
      const splitDate = this.validate.split('/')
      const splitName = this.managerName.split(' ')

      return {
        firstName: splitName[0],
        lastName: splitName[splitName.length - 1],
        month: splitDate[0],
        year: splitDate[1],
      }
    },
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  watch: {
    creditCardNumber() {
      const flagByIugu = window.Iugu.utils.getBrandByCreditCardNumber(this.creditCardNumber)
      this.flag = flagByIugu || 'generic'
      return flagByIugu
    },
  },
  mounted() {
    localize('pt_BR')

    this.getCompanyPaymentMethods()
    this.getRangesIndications()

    // recebendo as informações da url
    const queryParam = this.$route.query.activeAutoRenew
    this.activeAutoRenew = queryParam
    if (queryParam) this.renew = true
  },
  methods: {
    alertRequireMoreIndications() {
      this.$swal({
        title: '<span class="mt-4 font-weight-bolder">Recebemos sua intenção de mudança de plano :)</span>',
        // icon: 'info',
        html:
          '<p class="mt-2">Em breve você vai receber um contato da nossa equipe, fique atento!</p>',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: 'Ok!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    getRangesIndications() {
      useJwt.getAllRangesIndications()
        .then(response => {
          const rangesByApi = response.data.result.filter(range => range.isActive === true)
          this.ranges = rangesByApi.sort((a, b) => ((a.price < b.price) ? 1 : -1))
        })
        .catch(error => error)
    },
    getCompanyPaymentMethods() {
      useJwt.checkCompanyPaymentMethods()
        .then(response => {
          const result = JSON.parse(response.data.result)

          this.defaultPaymentMethodId = result.default_payment_method_id

          const paymentListMethodsRaw = result.payment_methods
          const idxDefaultMethod = paymentListMethodsRaw.findIndex(element => element.id === result.default_payment_method_id)

          // alterando a ordem dos cartões para exibir o cartão favorito no começo da lista
          const paymentListMethods = changePositionItemInArray(paymentListMethodsRaw, idxDefaultMethod, 0)

          if (paymentListMethods.length < 1) {
            this.listSavedCreditCards = null
            return
          }

          const formattedList = paymentListMethods.map(item => ({
            id: item.id,
            flag: item.data.brand.toLowerCase(),
            name: `${item.data.brand} ${item.data.last_digits}`,
            number: item.data.masked_number
              .replaceAll('X', '*')
              .replaceAll('-', ' '),
          }))

          this.useCreditCardsSavedList = true
          this.listSavedCreditCards = formattedList
        })
        .catch(error => {
          // this.$swal({
          //   title: 'Ops! Ocorreu um problema.',
          //   text: `${error.response.data.errors[0]}. Entre em contato com o suporte`,
          //   icon: 'error',
          //   customClass: {
          //     confirmButton: 'btn btn-primary',
          //   },
          //   buttonsStyling: false,
          // })
          // this.$router.push({ path: '/' })
          this.listSavedCreditCards = null
          return error
        })
    },
    async createPayment(params) {
      const payload = {
        // refazer este payload
        planId: this.getIdSelectPlan,
        indications: this.indications,
        value: this.getTotalPrice.newPrice,
        autoRenewal: this.renew,
        set_as_default: 'true',
        ...params,
      }

      try {
        const response = await useJwt.createPayment(payload)

        if (!response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ocorreu um erro ao tentar executar o pagamento.',
              icon: 'DollarSignIcon',
              variant: 'danger',
            },
          })
          return false
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pagamento realizado com sucesso!',
            icon: 'DollarSignIcon',
            variant: 'success',
          },
        })

        document.querySelector('.wizard-nav-pills').style.display = 'none'

        store.commit('company/TOGGLE_STATUS_COMPANY', true)
        return true
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro ao tentar executar o pagamento.',
            icon: 'DollarSignIcon',
            variant: 'danger',
          },
        })
        return false
      }
    },
    async tokenizeIugu() {
      const $this = this
      async function iuguCAllBackHandler(response) {
        if (response.errors) {
          $this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Verifique os dados do cartão.',
              icon: 'DollarSignIcon',
              variant: 'danger',
            },
          })
          return false
        }
        $this.tokenCard = response.id
        const res = await $this.createPayment({ tokenCard: response.id, saveCard: $this.saveCard })

        if (res) {
          $this.$refs.formWizard.changeTab(1, 2)
          $this.$refs.formWizard.tabs[1].validationError = false
        }
        return true
      }

      try {
        const iuguResponse = await window.Iugu.createPaymentToken(
          window.Iugu.CreditCard(
            this.creditCardNumber,
            this.getFormattedInfoCreditCard.month,
            this.getFormattedInfoCreditCard.year,
            this.getFormattedInfoCreditCard.firstName,
            this.getFormattedInfoCreditCard.lastName,
            this.cvcCode,
          ), iuguCAllBackHandler,
        )
        return iuguResponse
      } catch (err) {
        return false
      }
    },
    async validateStepN() {
      const $refStep = this.$refs['step-1']
      try {
        const isValidForm = await $refStep.validate()

        if (!isValidForm) {
          return false
        }

        if (!this.useCreditCardsSavedList) {
          const res = await this.tokenizeIugu($refStep)
          return res
        }

        return this.createPayment({ tokenMethodId: this.selectedCardId })
      } catch (err) {
        return false
      }
    },
    formSubmitted() {
      this.$router.push({ path: '/financial/company-plan' })
    },
  },

}
</script>

<style lang="scss" scope>
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-slider.scss';

  .box-prices .card-body {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }

  .spinbutton-primary {
        button {
            background-color: #6270ff !important; //#7367f0
            color: #fff;
        }
    }

    .box-total {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #E6E4EB;
        border-radius: 0 !important;

        h5 {
            margin: 0.1rem 0
        }

        strong {
            font-size: 1.5rem;
            color: #7367f0;
            font-weight: bold;
        }
    }

    label.custom-control-label {
        cursor: pointer !important;
    }

    .wizard-footer-left { display: none !important; }
    .custom-control-label { width: 100% !important; }

    .box-credit-card {
        border: 1px solid #ddd;
        margin: 15px 0 !important;

        .card_flag {
            max-height: 4.5rem;
        }

        .card-body {
            padding: 1rem 1.5rem !important;
        }

    }

        .box-flag {
          img {
            min-height: 24px !important;
          }

          svg {
            display: none;
          }
        }

    .checked { border: 1px solid #153BC7; }

    button.wizard-btn {
    background: #28C76F !important;
    border-color: #28C76F !important;
    }

    .success-text {
      color: #28C76F
    }

    /* reset do form wizard content */
    .wizard-tab-content {
      background: none !important;
      box-shadow: 0 0 0 0 !important;
    }

    .wizard-card-footer.clearfix {
      background-color: transparent!important;
      box-shadow: 0 0 0 0 !important;
    }

    .vue-slider-rail {
      height: 4px !important;
    }
</style>
